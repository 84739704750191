export default class TeamCard {
    constructor(node) {
        this.node = node;
        this.toggleNode = this.node.querySelector('[data-ref="toggle"]');
        this.toggleNode.addEventListener('click', this.handleToggleClick.bind(this));
    }

    handleToggleClick() {
        const expanded = !(this.toggleNode.getAttribute('aria-expanded') === 'true');
        this.toggleNode.setAttribute('aria-expanded', expanded.toString());
        this.node.classList.toggle('is-expanded', expanded);
    }
}
