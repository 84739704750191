export const FETCH_OPTS = { headers: { 'X-Requested-With': 'XMLHttpRequest' } };

export const THEME_SCOPE_HEADER = 'header';

export const VIMEO = 'vimeo';
export const YOUTUBE = 'youtube';
export const VIMEO_PLAYER_OPTS = {
    dnt: true,
    colors: ['000000', 'FFFFFF', 'FFFFFF', '000000'],
};
export const YOUTUBE_PLAYER_OPTS = {
    playerVars: {
        color: 'white',
        rel: 0,
    },
    host: 'https://www.youtube-nocookie.com',
};
