import { FETCH_OPTS } from '@lib/constants';

export default class CardGrid {
    constructor(node) {
        this.node = node;
        this.name = this.node.dataset.name;
        this.listNode = this.node.querySelector('[data-ref="list"]');

        this.node.addEventListener('click', this.handleClick.bind(this));

        if ('IntersectionObserver' in window) {
            this.setupPaginationTriggerObserver();
            this.observePaginationTriggers();
        }
    }

    activatePaginationTrigger(paginationTriggerNode) {
        paginationTriggerNode.setAttribute('disabled', 'true');
        const pageUrl = paginationTriggerNode.getAttribute('href');
        this.fetchPage(pageUrl);
    }

    fetchPage(url) {
        this.node.setAttribute('aria-busy', 'true');
        if (this.controller) this.controller.abort();
        this.controller = new AbortController();
        const { signal } = this.controller;
        fetch(url, { ...FETCH_OPTS, signal })
            .then((response) => response.text())
            .then((html) => this.renderResults(html))
            .catch((err) => err.name !== 'AbortError' && console.error(err))
            .finally(() => {
                this.controller = null;
                this.node.setAttribute('aria-busy', 'false');
            });
    }

    handleClick(event) {
        if (event.target.closest('[data-ref="pagination-trigger"]')) {
            this.handlePaginationTriggerClick(event);
        }
    }

    handlePaginationTriggerClick(event) {
        event.preventDefault();
        const paginationTriggerNode = event.target.closest('[data-ref="pagination-trigger"]');
        this.activatePaginationTrigger(paginationTriggerNode);
    }

    handlePaginationTriggerIntersection(paginationTriggerNode) {
        this.activatePaginationTrigger(paginationTriggerNode);
    }

    observePaginationTriggers() {
        this.paginationTriggerObserver.disconnect();
        const paginationTriggerNode = this.node.querySelector('[data-ref="pagination-trigger"]');
        if (paginationTriggerNode) {
            this.paginationTriggerObserver.observe(paginationTriggerNode);
        }
    }

    renderResults(html) {
        const paginationTriggerNode = this.node.querySelector('[data-ref="pagination-trigger"]');
        const itemNode = paginationTriggerNode.closest('[data-ref="item"]');
        itemNode.parentNode.removeChild(itemNode);

        const doc = new DOMParser().parseFromString(html, 'text/html');
        const sourceNode = this.name
            ? doc.querySelector(`[data-component="card-grid"][data-name="${this.name}"]`)
            : doc.querySelector(`[data-component="card-grid"]`);
        const sourceListNode = sourceNode.querySelector('[data-ref="list"]');
        this.listNode.insertAdjacentHTML('beforeend', sourceListNode.innerHTML);
        this.observePaginationTriggers();
    }

    setupPaginationTriggerObserver() {
        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.handlePaginationTriggerIntersection(entry.target);
                    observer.unobserve(entry.target);
                }
            });
        };
        const intersectionOptions = {
            threshold: [0, 0.25, 0.5, 0.75, 1],
            root: document,
            rootMargin: '100px 0px 0px 0px',
        };
        this.paginationTriggerObserver = new IntersectionObserver(
            handleIntersection,
            intersectionOptions,
        );
    }
}
