export default class Dropdown {
    constructor(node) {
        this.node = node;
        this.toggleNode = this.node.querySelector('[data-ref="toggle"]');
        this.linkNodes = this.node.querySelectorAll('[data-ref="link"]');

        this.toggleNode.addEventListener('click', this.handleToggleClick.bind(this));
    }

    handleToggleClick() {
        const expanded = !(this.toggleNode.getAttribute('aria-expanded') === 'true');
        this.toggleNode.setAttribute('aria-expanded', expanded.toString());

        this.linkNodes.forEach((linkNode) =>
            linkNode.setAttribute('tabindex', expanded ? '0' : '-1'),
        );
    }
}
