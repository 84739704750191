export default class TabGroup {
    constructor(node) {
        this.node = node;

        this.tabNodes = this.node.querySelectorAll('[data-ref="tab"]');
        this.tabPanelNodes = this.node.querySelectorAll('[data-ref="tab-panel"]');

        this.node.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick(event) {
        if (event.target.closest('[data-ref="tab"]')) {
            this.handleTabClick(event);
        }
    }

    handleTabClick(event) {
        const tabNode = event.target.closest('[data-ref="tab"]');
        const tabPanelId = tabNode.getAttribute('aria-controls');
        const tabPanelNode = [...this.tabPanelNodes].find(
            (tabPanelNode) => tabPanelNode.getAttribute('id') === tabPanelId,
        );
        this.tabNodes.forEach((_tabNode) =>
            _tabNode.setAttribute('aria-selected', _tabNode === tabNode ? 'true' : 'false'),
        );
        this.tabPanelNodes.forEach((_tabPanelNode) =>
            _tabPanelNode.classList.toggle('is-selected', _tabPanelNode === tabPanelNode),
        );
    }
}
