import { THEME_SCOPE_HEADER } from '@lib/constants';
import { prefersReducedMotion } from '@lib/media';

export default class HeroCarousel {
    constructor(node) {
        this.node = node;

        this.timeout = 7000;
        this.timer = null;
        this.playing = false;
        this.paused = false;

        this.controlButtonNode = this.node.querySelector('[data-ref="control"]');
        this.slideNodes = this.node.querySelectorAll('[data-ref="slide"]');

        this.node.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
        this.node.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
        this.controlButtonNode.addEventListener('click', this.handleControlButtonClick.bind(this));

        document.addEventListener(
            'visibilitychange',
            this.handleDocumentVisibilityChange.bind(this),
        );

        this.dispatchThemeChangeEvent();
        if (this.slideNodes.length > 1 && !prefersReducedMotion.matches) {
            this.play();
        } else {
            this.controlButtonNode.parentNode.removeChild(this.controlButtonNode);
        }
    }

    dispatchThemeChangeEvent() {
        const currentSlideNode = [...this.slideNodes].find(
            (node) => node.getAttribute('aria-current') === 'true',
        );
        const { theme } = currentSlideNode.dataset;
        if (theme) {
            this.node.dispatchEvent(
                new CustomEvent('maestro:theme:change', {
                    bubbles: true,
                    detail: {
                        theme,
                        scope: THEME_SCOPE_HEADER,
                    },
                }),
            );
        }
    }

    handleControlButtonClick() {
        if (this.playing) {
            this.stop();
        } else {
            this.play();
        }
    }

    handleDocumentVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this.pause();
        } else {
            this.unpause();
        }
    }

    handleMouseEnter() {
        this.pause();
    }

    handleMouseLeave() {
        this.unpause();
    }

    next() {
        if (this.paused) return;

        const currentSlideNode = [...this.slideNodes].find(
            (node) => node.getAttribute('aria-current') === 'true',
        );
        const currentSlideIndex = [...this.slideNodes].indexOf(currentSlideNode);
        const nextSlideIndex =
            currentSlideIndex === this.slideNodes.length - 1 ? 0 : currentSlideIndex + 1;
        const nextSlideNode = this.slideNodes[nextSlideIndex];
        currentSlideNode.removeAttribute('aria-current');
        nextSlideNode.setAttribute('aria-current', 'true');
        this.dispatchThemeChangeEvent();
    }

    pause() {
        this.paused = true;
    }

    unpause() {
        this.paused = false;
    }

    play() {
        this.playing = true;
        this.paused = false;
        this.timer = window.setInterval(this.next.bind(this), this.timeout);
        this.updateControlButton();
    }

    stop() {
        this.playing = false;
        window.clearInterval(this.timer);
        this.updateControlButton();
    }

    updateControlButton() {
        this.controlButtonNode.innerText = this.playing ? 'Stop carousel' : 'Play carousel';
    }
}
