import { scaleLinear } from 'd3-scale';

export default class Scrollbar {
    constructor(node) {
        this.node = node;
        this.node.addEventListener('maestro:scrollable:scroll', this.handleScroll.bind(this));
    }

    handleScroll(event) {
        const { clientWidth, scrollLeft, scrollWidth } = event.detail;

        if (clientWidth === scrollWidth) {
            // Handle case of content not wide enough to scroll
            this.min = 0;
            this.max = 0;
            this.value = 0;
            this.width = 0;
            this.left = 0;
        } else {
            // Determine width that should be applied to scrollbar thumb
            const widthScale = scaleLinear()
                .domain([0, scrollWidth])
                .rangeRound([0, 100])
                .clamp(true);
            const width = widthScale(clientWidth);

            // Determine left position that should be applied to scrollbar thumb,
            // accounting for width so that thumb remains confined to track
            const scrolledScale = scaleLinear()
                .domain([0, scrollWidth - clientWidth])
                .rangeRound([0, 100])
                .clamp(true);
            const scrolled = scrolledScale(scrollLeft);
            const leftScale = scaleLinear()
                .domain([0, 100])
                .rangeRound([0, 100 - width])
                .clamp(true);
            const left = leftScale(scrolled);

            this.min = 0;
            this.max = 100;
            this.value = scrolled;
            this.width = width;
            this.left = left;
        }
        this.updateScrollbar();
    }

    updateScrollbar() {
        this.node.setAttribute('aria-valuemin', this.min);
        this.node.setAttribute('aria-valuemax', this.max);
        this.node.setAttribute('aria-valuenow', this.value);
        this.node.style.setProperty('--scrollbar-thumb-width', `${this.width}%`);
        this.node.style.setProperty('--scrollbar-thumb-left', `${this.left}%`);
    }
}
