import { throttle } from 'throttle-debounce';

export default class ScrollbarDriver {
    constructor(node) {
        this.node = node;
        this.scrollableNodes = this.node.querySelectorAll('[data-ref="scrollable"]');
        this.scrollbarNode = this.node.querySelector('[data-ref="scrollbar"]');

        this.scrollableNodes.forEach((scrollableNode) =>
            // XXX: `scroll` event doesn't bubble
            scrollableNode.addEventListener('scroll', throttle(100, this.handleScroll.bind(this))),
        );

        this.setupResizeObserver();
    }

    handleScroll(event) {
        this.updateScrollbar(event.target);
    }

    updateScrollbar(scrollableNode) {
        const { clientWidth, scrollLeft, scrollWidth } = scrollableNode;
        this.scrollbarNode.dispatchEvent(
            new CustomEvent('maestro:scrollable:scroll', {
                detail: {
                    clientWidth,
                    scrollLeft,
                    scrollWidth,
                },
            }),
        );
    }

    setupResizeObserver() {
        const resizeObserver = new window.ResizeObserver((entries) => {
            entries.forEach((entry) => {
                // Detect if resized element is visible (observer will fire
                // when target element is hidden)
                // source: https://www.w3.org/TR/resize-observer/#intro
                // source: https://stackoverflow.com/a/21696585/258794
                if (entry.target.offsetParent !== null) {
                    this.updateScrollbar(entry.target);
                }
            });
        });

        this.scrollableNodes.forEach((scrollableNode) => resizeObserver.observe(scrollableNode));
    }
}
