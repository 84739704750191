import CardGrid from '@components/card-grid';
import CookieBanner from '@components/cookie-banner';
import Dropdown from '@components/dropdown';
import EntryBrowser from '@components/entry-browser';
import Footer from '@components/footer';
import Header from '@components/header';
import HeroCarousel from '@components/hero-carousel';
import MediaCarousel from '@components/media-carousel';
import Scrollbar from '@components/scrollbar';
import ScrollbarDriver from '@components/scrollbar-driver';
import TabGroup from '@components/tab-group';
import TeamCard from '@components/team-card';
import VideoEmbed from '@components/video-embed';

const components = {
    'card-grid': CardGrid,
    'cookie-banner': CookieBanner,
    'dropdown': Dropdown,
    'entry-browser': EntryBrowser,
    'footer': Footer,
    'header': Header,
    'hero-carousel': HeroCarousel,
    'media-carousel': MediaCarousel,
    'scrollbar': Scrollbar,
    'scrollbar-driver': ScrollbarDriver,
    'tab-group': TabGroup,
    'team-card': TeamCard,
    'video-embed': VideoEmbed,
};

const initComponents = (components, rootNode = document.body) => {
    rootNode.querySelectorAll('[data-component]').forEach((node) => {
        node.dataset.component
            .split(/\s+/)
            .filter((componentName) => componentName in components)
            .map((componentName) => new components[componentName](node));
    });
};
initComponents(components);

// Listen for our own content load event to rebind component behaviour
window.addEventListener('maestro:ajax:load', (event) => {
    console.log('Binding components in loaded content', event.target);
    initComponents(components, event.target);
});
